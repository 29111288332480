import React, { useEffect, useState } from "react";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { PATTERNS } from "../validetion";
import { Card, CardContent, FormControl, Grid } from "@mui/material";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import {
  postJsonData,

} from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import SubmitButton from "../coustombutton/SubmitButton";
import { useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";

const AddUser = () => {
  const [formData, setFormData] = useState({
    name: {
      value: "",
      isValid: true,
    },

    phone: {
      value: "",
      isValid: true,
    },
    email: {
      value: "",
      isValid: true,
    },

    password: {
      value: "",
      isValid: true,
    },
  });
  const [userRole, setUserRole] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isDisabledNew, setIsDisabledNew] = useState(false);

  const navigate = useNavigate();
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  const handleUserRole = (event) => {
    setUserRole(event);
  };


  const handleUser = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
 

    setFormSubmitted(true);
    const isFormValid =
      formData.phone.isValid &&
      formData.email.isValid &&
      userRole &&
      formData.phone.value.trim() !== "";

    if (isFormValid) {
      const data = {
        name: formData.name.value,
        password: formData.password.value,
        mobileNumber: formData.phone.value,
        email: formData.email.value,
        role: userRole?.value,
      };
      postJsonData(
        VisitorEndpoint.CREATE_ADMIN,
        data,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 201) {
              toast.success(res.data.message);
              navigate("/admin");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };

  return (
    <div className="container_fluid_new">
      <div className="container_new">
        <div>
          <ActiveLastBreadcrumb
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Admin", href: "/admin" },
              { name: "Add User", href: "/create-user" },
            ]}
          />
        </div>
        <h1 className="have_solution_heading">Add User</h1>

        <form onSubmit={handleUser}>
          <Card sx={{ padding: "20px" }}>
            <CardContent>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>User Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the name of the User.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="name"
                      type="text"
                      placeholder="Enter User Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.name.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "name", null);
                      }}
                    />
                    {/* {formData.brandname.isValid || (
                      <p className="input_text_error">
                        *Please enter the name of the brand.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Mobile Number
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the mobile number of the user's primary point of
                    contact.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="phone"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter Brand Poc Number"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.phone.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "phone", PATTERNS.MOBILE);
                      }}
                    />
                    {formData.phone.isValid || (
                      <p className="input_text_error">
                        *Please enter the mobile number of the user's.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Email</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the email address of the user's primary point of
                    contact.{" "}
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="email"
                      type="text"
                      placeholder="Enter User Email"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.email.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "email", PATTERNS.EMAIL);
                      }}
                    />
                    {formData.email.isValid || (
                      <p className="input_text_error">
                        *Please enter the email address of the user's.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>Password</CustomRequiredLabel>
                  <CustomSubheadingh>Enter the password.</CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="password"
                      type="text"
                      placeholder="Enter Password"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.password.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "password", PATTERNS.PASSWORD);
                      }}
                    />
                    {formData.password.isValid || (
                      <p className="input_text_error">
                        *Please enter your password Ex:Next@12345.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>User Role</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the role in which user is associated in.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={[
                        { label: "Admin", value: "Admin" },
                        { label: "Operations", value: "Operations" },
                        { label: "Brand", value: "Brand" },
                        { label: "Sales", value: "Sales" },
                        { label: "Influencer", value: "Influencer" },
                      ]}
                      value={userRole}
                      onChange={handleUserRole}
                      name="userRole"
                    />
                    {formSubmitted && !userRole && (
                      <p className="input_text_error">
                        *Please select at least one User role.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <SubmitButton
                type={isDisabledNew ? "button" : "submit"}
                label={isDisabledNew ? "Loading..." : "Submit"}
                disabled={isDisabledNew}
              />{" "}
            </CardContent>
          </Card>
        </form>
      </div>
    </div>
  );
};
export default AddUser;
