import React, { useEffect, useState } from "react";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { PATTERNS } from "../validetion";
import { Card, CardContent, FormControl, Grid } from "@mui/material";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import {
  postJsonData,

} from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import SubmitButton from "../coustombutton/SubmitButton";
import { useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";

const ChangePassword = () => {
  const [formData, setFormData] = useState({


    oldPassword: {
      value: "",
      isValid: true,
    },

    newPassword: {
      value: "",
      isValid: true,
    },
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isDisabledNew, setIsDisabledNew] = useState(false);

  const navigate = useNavigate();
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

 
 

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
 

    setFormSubmitted(true);
    const isFormValid =
      formData.oldPassword.isValid &&
      formData.newPassword.isValid &&
      
      formData.oldPassword.value.trim() !== "" && formData.newPassword.value.trim() !== "" ;

    if (isFormValid) {
      const data = {
        oldPassword: formData.oldPassword.value,
        newPassword: formData.newPassword.value,
      
      };
      postJsonData(
        VisitorEndpoint.ADMIN_CHANGE_PASSWORD,
        data,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 200) {
              toast.success(res.data.message);
              navigate("/admin");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };

  return (
    <div className="container_fluid_new">
      <div className="container_new">
        <div>
          <ActiveLastBreadcrumb
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Brands", href: "/admin" },
              { name: "Change Password", href: "/change-password" },
            ]}
          />
        </div>
        <h1 className="have_solution_heading">Change Password</h1>

        <form onSubmit={handleChangePassword}>
          <Card sx={{ padding: "20px" }}>
            <CardContent>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>Old Password</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the old password of the User.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="oldPassword"
                      type="text"
                      placeholder="Enter Old Password"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.oldPassword.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "oldPassword", PATTERNS.PASSWORD)                      }}
                    />
                    {formData.oldPassword.isValid || (
                      <p className="input_text_error">
                          *Please enter your old password.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    New Password
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                  Enter the new password of the User.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="newPassword"
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter New Password"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.newPassword.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "newPassword", PATTERNS.PASSWORD);
                      }}
                    />
                    {formData.newPassword.isValid || (
                      <p className="input_text_error">
                          *Please enter your new password Ex:Next@12345.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
           
            
          
              <SubmitButton
                type={isDisabledNew ? "button" : "submit"}
                label={isDisabledNew ? "Loading..." : "Submit"}
                disabled={isDisabledNew}
              />{" "}
            </CardContent>
          </Card>
        </form>
      </div>
    </div>
  );
};
export default ChangePassword;
