export const BASE_URL = process.env.REACT_APP_BASE_URL;

const VisitorEndpoint = {
  // user apis

  LOGIN_ADMIN: "/api/v1/admin/login",
  CREATE_ADMIN: "/api/v1/admin/register",
  ADMIN_CHANGE_PASSWORD: "/api/v1/admin/change-password",
  GET_ALL_OPERATIONS: "/api/v1/admin/all?role=Operations",
  ADD_MANAGER: "/api/v1/order/ordermanager",
  REIMBURSEMENT_EXCEL: "/api/v1/reimbursement/reimburseorderfromexcel",
  ALL_FILTER: "/api/v1/filter/all",


  // get apis
  
  ALL_BRAND: "/api/v1/brand/all",
  ALL_PRODUCT: "/api/v1/product/all",

  ALL_PAYMENT: "/api/v1/payment/all",
  ALL_REIMBURSEMENT: "/api/v1/reimbursement/all",
  ALL_CAMPAIGNS: "/api/v1/campaign/all",
  // ALL_ORDERS: "/api/v1/order/all",
  ALL_ORDERS: "/api/v1/order/allwithreimbursed",
  ALL_INFLUNCERS: "/api/v1/influencer/all",
  ALL_COLLAb: "/api/v1/colab/all",
  GET_SUBADMIN_DATA: "/api/v1/admin/all",
  // add forms

  ADD_BRAND: "/api/v1/brand/create",
  ADD_PRODUCT: "/api/v1/product/create",
  ADD_INFLUNCER: "/api/v1/influencer/create",


  // get apis 
  ADD_BRANDCATEGORY: "/api/v1/brandcategory/all",
  ADD_INFLUNCERCATEGORY: "/api/v1/influencercategory/all",
// add forms

  ADD_PAYMENT: "/api/v1/payment/create",
  ADD_REIMBURSEMENT: "/api/v1/reimbursement/create",
  ADD_CAMPAIGN: "/api/v1/campaign/create",
  ADD_ORDERS: "/api/v1/order/create",
  ADD_COLLAB: "/api/v1/colab/create",

  // edit forms
  EDIT_PRODUCT: "/api/v1/product/update",
  EDIT_BRAND: "/api/v1/brand/update",
  EDIT_BRANDCATEGORY: "/api/v1/brandcategory/update",
  EDIT_CAMPAIGNREQUIREMENT: "/api/v1/campaignrequirement/update",
  EDIT_CAMPAIGNTYPE: "/api/v1/campaigntype/update",

  EDIT_COLABTYPE: "/api/v1/colabtype/update",
  EDIT_STATUS: "/api/v1/status/update",
  EDIT_INFLUENCERCATEGORY: "/api/v1/influencercategory/update",
  EDIT_PAYMENTMODE: "/api/v1/paymentmode/update",
  EDIT_PLATFORM: "/api/v1/platform/update",
  EDIT_ORDER: "/api/v1/order/update",
  EDIT_COLLAB: "/api/v1/colab/update",

  EDIT_PAYMENT: "/api/v1/payment/update",
  EDIT_REIMBURSEMENT: "/api/v1/reimbursement/update",
  EDIT_INFLUNCER: "/api/v1/influencer/update",
  EDIT_CAMPAIGN: "/api/v1/campaign/update",

  // master data
  ALL_COUNTRY: "/api/v1/country/all",
  ALL_STATE: "/api/v1/state/statebycountry",
  ALL_CITY: "/api/v1/city/citybystate",
  ALL_CITY_BY_COUNTRY: "/api/v1/city/citybycountry/India",
  COLAB_TYPE: "/api/v1/colabtype/all",

  ADD_STATUS: "/api/v1/status/create",
  ADD_PLATFORM: "/api/v1/platform/create",
  ADD_CAMPAIGNTYPE: "/api/v1/campaigntype/create",
  ADD_PAYMENTMODE: "/api/v1/paymentmode/create",
  ADD_CAMPAIGNREQUIREMENT: "/api/v1/campaignrequirement/create",
  ALL_CAMPAIGNREQUIREMENT: "/api/v1/campaignrequirement/all",
  POST_BRANDCATEGORY: "/api/v1/brandcategory/create",
  POST_INFLUENCERCATEGORY: "/api/v1/influencercategory/create",
  ADD_COLABTYPE: "/api/v1/colabtype/create",

  STATUS_LIST: "/api/v1/status/all",
  PLATFORM_LIST: "/api/v1/platform/all",
  PAYMENT_LIST: "/api/v1/paymentmode/all",
  CAMPAIGN_TYPE: "/api/v1/campaigntype/all",

  //DElETE
  DELETE_STATUS: "/api/v1/status/delete",
  DELETE_PLATFORM: "/api/v1/platform/delete",
  DELETE_CAMPAIGNTYPE: "/api/v1/campaigntype/delete",
  DELETE_PAYMENTMODE: "/api/v1/paymentmode/delete",
  DELETE_CAMPAIGNREQUIREMENT: "/api/v1/campaignrequirement/delete",
  DELETE_BRANDCATEGORY: "/api/v1/brandcategory/delete",
  DELETE_INFLUENCERCATEGORY: "/api/v1/influencercategory/delete",
  DELETE_COLABTYPE: "/api/v1/colabtype/delete",
  DELETE_PAYMENT: "/api/v1/payment/delete",
  DELETE_REIMBURSEMENT: "/api/v1/reimbursement/delete",
  DELETE_PRODUCT: "/api/v1/product/delete",
  DELETE_ORDER: "/api/v1/order/delete",
  DELETE_COLLAB: "/api/v1/colab/delete",
  DELETE_INFLUNCERS: "/api/v1/influencer/delete",
  DELETE_CAMAPIGN: "/api/v1/campaign/delete",


  //BRAND DETAILS
  GET_TOTAL_CAMPAIGN_BY_BRAND: "/api/v1/campaign/bybrand/totalcampaign",
  GET_TOTAL_ACTIVE_CAMPAIGN_BY_BRAND:
    "/api/v1/campaign/bybrand/totalactivecampaign",
  ALL_ACTIVE_CAMPAIGN_BY_BRAND: "/api/v1/campaign/activecampaignbybrand",
  GET_TOTAL_PAYMENT_BY_BRAND: "/api/v1/payment/totalpaymentbybrand",
  GET_TOTAL_PENDING_PAYMENT_BY_BRAND: "/api/v1/payment/paymentpendingbybrand",
  GET_BRAND_DETAILS: "/api/v1/brand/byid",

  //INFLUNCERS DETAILS
  GET_INFLUNCER_STAGE_DETAILS: "/api/v1/influencer/byid",
  GET_PARTICIPATED_CAMPAIGN: "/api/v1/influencer/totalcampaign",
  GET_MTOTAL_ACTIVE_CAMPAIGN: "/api/v1/influencer/totalactivecampaign",
  GET_MREIMBURSEMENT_MODE_CAMPAIGN: "/api/v1/influencer/totalreimbursement",
  GET_MREIMBURSEMENT_PENDING: "/api/v1/influencer/totalpendingamount",
  GET_ALL_ACTIVE_CAMPAIGN: "/api/v1/influencer/allactivecampaign",

  DELETE_BRAND: "/api/v1/brand/delete",

  // Dashboard
  GET_DASHBOARD_COUNT: "/api/v1/dashboard/allcount",
  GET_DASHBOARD_PAYMENT: "/api/v1/dashboard/allpaymenttotal",
  GET_DASHBOARD_REIMBURSMENT: "/api/v1/dashboard/allreimbursementtotal",


  // campaign details
  GET_CAMPAIGN_PLACED_ORDER : "/api/v1/campaign/byorder/totalplacedorder",
  GET_CAMPAIGN_PENDING_ORDER : "/api/v1/campaign/byorder/totalpendingorder",
  GET_CAMPAIGN_REVIEW_ORDER : "/api/v1/campaign/byorder/totalreviewedorder",
  GET_CAMPAIGN_EXHAUST_ORDER_COST : "/api/v1/campaign/byorder/exhaustedordercost",
  GET_CAMPAIGN_PAID_REIMBURSEMENT_COST : "/api/v1/campaign/paidreimbursement",
  GET_CAMPAIGN_BY_ID : "/api/v1/campaign/byid",
  GET_CAMPAIGN_PRODUCT : "/api/v1/product/bybrand",
  GET_CAMPAIGN_BRAND_PENDING_PAYMENT : "/api/v1/campaign/bybrand/pendingpayment",
  GET_CAMPAIGN_BY_ORDER_PRODUCTNAME : "/api/v1/campaign/byorder/countbyproductname",
  GET_CAMPAIGN_BY_ORDER_PLATFORMNAME : "/api/v1/campaign/byorder/countbyplatform",
  GET_CAMPAIGN_RECIVED_PAYMENT : "/api/v1/campaign/receivedpayment",
  GET_CAMPAIGN_RECIVED_CANCELLED : "/api/v1/campaign/byorder/totalcancelledorreturnedorder",
  BRAND_CAMPAIGN_STATS : "/api/v1/campaign/campaignstats",
  ALL_CAMPAIGN_PROGRESS : "/api/v1/campaign/allcampaignstats",

  // COLLAB
  GET_CAMPAIGN_COLLAB_COMPLETED : "/api/v1/campaign/bycolab/totalcompletedcolab",
  GET_CAMPAIGN_COLLAB_PENDING : "/api/v1/campaign/bycolab/totalpendingcolab",


  // web resiter forms
  GET_WEB_INFLUNCERS : "/api/v1/influencerdetails/all",
  DELETE_WEB_INFLUNCERS : "/api/v1/influencerdetails/delete",
  GET_WEB_BRANDS : "/api/v1/branddetails/all",
  DELETE_WEB_BRANDS_DETAILS: "/api/v1/branddetails/delete",
  // admin note apis

  ADMIN_BRAND_NOTE: "/api/v1/brand/adminnote",
  ADMIN_INFLUENCERS_NOTE: "/api/v1/influencer/adminnote",
  ADMIN_REIMBURSEMENT_NOTE: "/api/v1/reimbursement/adminnote",
  ADMIN_PAYMENT_NOTE: "/api/v1/payment/adminnote",
  ADMIN_PRODUCT_NOTE: "/api/v1/product/adminnote",
  ADMIN_CAMAPIGN_NOTE: "/api/v1/campaign/adminnote",
  ADMIN_ORDER_NOTE: "/api/v1/order/adminnote",
  ADMIN_COLLAB_NOTE: "/api/v1/colab/adminnote",
  // ADMIN_COLLAB_NOTE: "/api/v1/dashboard/allordergraph?startdate=2024-01-01&enddate=2024-01-31,

  // CHARTS
  ADMIN_ORDER_CHART: "/api/v1/dashboard/allordergraph",
  ADMIN_COLLAB_CHART: "/api/v1/dashboard/allcolabgraph",
  CAMPAIGN_COLLAB_CHART: "/api/v1/campaign/bycolab/colabgraph",
  CAMPAIGN_ORDER_CHART: "/api/v1/campaign/byorder/ordergraph",

// INFLUNCERS UPI
GET_INFLUNCER_UPI:"/api/v1/influencer/upidata",


// Add Review
ADD_ORDER__REVIEW:"/api/v1/order/addreviewscreenshot",


// Add Review
EXPORT_EXCEL:"/api/v1/influencerdetails/allexport",
EXPORT_EXCEL_ORDERS:"/api/v1/order/allexport",

// campaignanalytics
CAMAPAIGN_ANALYTICS:"/api/v1/campaignanalytics/all",



};

export default VisitorEndpoint;
