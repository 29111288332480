import React from "react";
import VisitorsDetails from "../pages/VisitorsDetails";
import SubAdminComponent from "../pages/SubAdmin";

import AddBrand from "../component/add/AddBrand";
import BrandDetails from "../pages/brand/BrandComponent";
import ProductsDetails from "../pages/products/ProductComponent";
import AddProduct from "../component/add/AddProduct";
import BrandComponent from "../pages/brand/BrandComponent";
import AddMarqfluencer from "../component/add/AddMarqfluencer";
import MarqfluencerComponent from "../pages/Marqfluencer/MarqfluencerComponent";
import ProductComponent from "../pages/products/ProductComponent";
import CampaignComponent from "../pages/Campaigns/CampaignComponent";
import AddCampaign from "../component/add/AddCampaign";
import OrderComponent from "../pages/orders/OrderComponent";
import AddOrder from "../component/add/AddOrder";
import TransactionComponent from "../pages/Transaction/PaymentComponent";
import PaymentComponent from "../pages/Transaction/PaymentComponent";
import ReimbursementComponents from "../pages/Reimbursements/ReimbursementComponents";
import ReimbursementComponent from "../pages/Reimbursements/ReimbursementComponents";
import AddReimbursement from "../component/add/AddReimbursement";
import AddPayment from "../component/add/AddPayment";
import StatusComponent from "../pages/masterdata/StatusComponent";
import PlatformComponent from "../pages/masterdata/PlatformComponent";
import CampaignTypeComponent from "../pages/masterdata/CampaignTypeComponent";
import PaymentModeComponent from "../pages/masterdata/PaymentComponent";
import DashboardComponent from "../pages/dashboard/DashboardComponent";
import LoginForm from "../pages/LoginAdmin";
import AdminComponent from "../pages/admin/AdminComponent";

import BrandCategoryComponent from "../pages/masterdata/BrandCategoryComponent";
import InfluencerCategoryComponent from "../pages/masterdata/InfluencerCategoryComponent";
import CampaignRequirementComponent from "../pages/masterdata/CampaignRequirementComponent";
import ColabTypeComponent from "../pages/masterdata/ColabTypeComponent";

import AddCollab from "../component/add/AddCollab";
import CollabComponent from "../pages/collab/CollabComponent";
import EditBrand from "../component/edit/EditBrand";
import BrandStatsView from "../component/viewdetails/BrandStatsView";
import EditProduct from "../component/edit/EditProduct";
import EditPayment from "../component/edit/EditPayment";
import EditReimbursement from "../component/edit/EditReimbursement";
import EditMarqfluencer from "../component/edit/EditMarqfluencer";
import EditOrder from "../component/edit/EditOrder";
import EditCollab from "../component/edit/EditCollab";
import MarqfluencerStatsView from "../component/viewdetails/MarqfluencerStatsView";
import EditCampaign from "../component/edit/EditCampaign";
import CampaignStatsView from "../component/viewdetails/CampaignStatsView";
import WebInfluncerDetailsComponent from "../pages/webregisteretions/WebInfluncerDetailsComponent";
import WebBrandDetailsComponent from "../pages/webregisteretions/WebBrandDetailsComponent";
import CampaignAnalytics from "../pages/Transaction/CampaignAnalytics";
import CampaignProgress from "../pages/Campaigns/CampaignProgress";
import AddUser from "../component/add/AddUser";
import ChangePassword from "../component/add/ChangePassword";


//Common
// const PageNotFound = React.lazy(() => import("../error/PageNotFound"));
// const ForbiddenAccess = React.lazy(() => import("../error/ForbiddenAccess"));

// login-SignUp
// const Login = React.lazy(() => import("../pages/user/login"));
// const NewUserForm = React.lazy(() => import("../pages/user/NewUserForm"));
// const RecoverAccount = React.lazy(() => import("../pages/user/RecoverAccount"));

// public-Pages

// private pages
// const Opportunities = React.lazy(() => import("../pages/Opportunities/index"));

const configureRoutes = () => {
  const routes = [
    {
      element: <DashboardComponent />,
      exact: true,
      path: "/",
      title: "Home page",
      type: "private",
    },
    {
      element: <LoginForm />,
      exact: true,
      path: "/login",
      title: "Home page",
      type: "public",
    },
    {
      element: <BrandComponent />,
      exact: true,
      path: "/brand",
      title: "Brand Details",
      type: "private",
    },
    {
      element: <WebInfluncerDetailsComponent />,
      exact: true,
      path: "/marqfluencer/details",
      title: "Marqfluencer Details",
      type: "private",
    },
    {
      element: <WebBrandDetailsComponent />,
      exact: true,
      path: "/brand/details",
      title: "Brand Details",
      type: "private",
    },
    {
      element: <AddBrand />,
      exact: true,
      path: "/brand/add",
      title: "Add Brand",
      type: "private",
    },
    {
      element: <AddUser />,
      exact: true,
      path: "/create-user",
      title: "Add User",
      type: "private",
    },
    {
      element: <ChangePassword />,
      exact: true,
      path: "/change-password",
      title: "Change Password",
      type: "private",
    },
    {
      element: <EditBrand />,
      exact: true,
      path: "/brand/edit",
      title: "Add Brand",
      type: "private",
    },
    {
      element: <EditProduct />,
      exact: true,
      path: "/product/edit",
      title: "Add Brand",
      type: "private",
    },
    {
      element: <EditPayment />,
      exact: true,
      path: "/payment/edit",
      title: "Add Brand",
      type: "private",
    },
    {
      element: <AddCampaign />,
      exact: true,
      path: "/campaign/add",
      title: "Add Campaign",
      type: "private",
    },
    {
      element: <EditCampaign />,
      exact: true,
      path: "/campaign/edit",
      title: "Edit Campaign",
      type: "private",
    },
    {
      element: <ProductComponent />,
      exact: true,
      path: "/product",
      title: "Product",
      type: "private",
    },
    {
      element: <CampaignComponent />,
      exact: true,
      path: "/campaign",
      title: "Campaign",
      type: "private",
    },
    {
      element: <MarqfluencerComponent />,
      exact: true,
      path: "/marqfluencer",
      title: "Marqfluencer",
      type: "private",
    },
    {
      element: <CampaignComponent />,
      exact: true,
      path: "/campaign",
      title: "Campaign",
      type: "private",
    },
    {
      element: <OrderComponent />,
      exact: true,
      path: "/order",
      title: "Order",
      type: "private",
    },
    {
      element: <ReimbursementComponent />,
      exact: true,
      path: "/reimbursement",
      title: "Reimbursement",
      type: "private",
    },
    {
      element: <PaymentComponent />,
      exact: true,
      path: "/payment",
      title: "Payment",
      type: "private",
    },
    {
      element: <AddMarqfluencer />,
      exact: true,
      path: "/marqfluencer/add",
      title: "Add Marqfluencer",
      type: "private",
    },
    {
      element: <EditMarqfluencer />,
      exact: true,
      path: "/marqfluencer/edit",
      title: "Add Marqfluencer",
      type: "private",
    },
    {
      element: <AddReimbursement />,
      exact: true,
      path: "/reimbursement/add",
      title: "Add Reimbursement",
      type: "private",
    },
    {
      element: <EditReimbursement />,
      exact: true,
      path: "/reimbursement/edit",
      title: "Edit Reimbursement",
      type: "private",
    },
    {
      element: <AddProduct />,
      exact: true,
      path: "/product/add",
      title: "Add Products",
      type: "private",
    },
    {
      element: <AddPayment />,
      exact: true,
      path: "/payment/add",
      title: "Add Products",
      type: "private",
    },
    {
      element: <CampaignAnalytics />,
      exact: true,
      path: "/pendingpayments",
      title: "Campaign Analytics",
      type: "private",
    },
    {
      element: <CampaignProgress />,
      exact: true,
      path: "/campaignprogress",
      title: "Campaign Progress",
      type: "private",
    },
    {
      element: <AddOrder />,
      exact: true,
      path: "/order/add",
      title: "Add Order",
      type: "private",
    },
    {
      element: <EditOrder />,
      exact: true,
      path: "/order/edit",
      title: "Edit Order",
      type: "private",
    },
    {
      element: <AddCollab />,
      exact: true,
      path: "/collab/add",
      title: "Add Collab",
      type: "private",
    },
    {
      element: <EditCollab />,
      exact: true,
      path: "/collab/edit",
      title: "Edit Collab",
      type: "private",
    },
    {
      element: <AdminComponent />,
      exact: true,
      path: "/admin",
      title: "sub admin",
      type: "private",
    },
    {
      element: <CollabComponent />,
      exact: true,
      path: "/collab",
      title: "Collab Component",
      type: "private",
    },
    {
      element: <StatusComponent />,
      exact: true,
      path: "/status",
      title: "Status",
      type: "private",
    },

    {
      element: <PlatformComponent />,
      exact: true,
      path: "/platform",
      title: "Platform",
      type: "private",
    },
    {
      element: <CampaignTypeComponent />,
      exact: true,
      path: "/campaigntype",
      title: "Campaign Type",
      type: "private",
    },
    {
      element: <PaymentModeComponent />,
      exact: true,
      path: "/paymentmode",
      title: "Payment Mode",
      type: "private",
    },
    {
      element: <BrandCategoryComponent />,
      exact: true,
      path: "/brandcategory",
      title: "Brand Category",
      type: "private",
    },
    {
      element: <InfluencerCategoryComponent />,
      exact: true,
      path: "/influencercategory",
      title: "Influencer Category",
      type: "private",
    },
    {
      element: <CampaignRequirementComponent />,
      exact: true,
      path: "/campaignrequirement",
      title: "Campaign Requirement",
      type: "private",
    },
    {
      element: <ColabTypeComponent />,
      exact: true,
      path: "/colabtype",
      title: "Colab Type",
      type: "private",
    },
    {
      element: <BrandStatsView />,
      exact: true,
      path: "/brand/stats/:id",
      title: "Brand View",
      type: "private",
    },
    {
      element: <MarqfluencerStatsView />,
      exact: true,
      path: "/marqfluencer/stats/:id",
      title: "Marqfluencer View",
      type: "private",
    },
    {
      element: <CampaignStatsView />,
      exact: true,
      path: "/campaign/stats/:id",
      title: "Campaign View",
      type: "private",
    },
    // {
    //   element: <PageNotFound />,
    //   exact: true,
    //   path: "*",
    //   title: "404 Not Found",
    //   type: "error",
    // },
  ];

  return routes;
};

export default configureRoutes;
